@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Koulen&display=swap");
@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

html, body {
  margin: 0px;
  padding: 0px;
}

a {
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
}

.button {
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.button-outline-primary {
  border: 2px solid #0F1A33;
  background: transparent;
  color: #0F1A33;
  padding: 10px 20px;

  &:hover {
    background: #0F1A33;
    color: #FFFFFF;
  }
}

.button-solid-primary {
  background: #0F1A33;
  color: #FFFFFF;
  padding: 12px 22px;

  &:hover {
    background: #D0AFE1;
  }
}

#form-message {
  
  .t-message {
    position: relative;
    background-color: #FFFFFF;
    padding: 20px 35px 20px 25px;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
  
    &.t-message-success {
      border-left: 3px solid #4CAF50;
      background-color: #c4ffc6;

      .t-message-icon {
        background-color: #4CAF50;
        &:before {
          content: "\f00c";
        }
      }
    }
  
    &.t-message-error {
      border-left: 3px solid #FF0000;
      background-color: #ffb5b5;

      .t-message-icon {
        background-color: #FF0000;
        &:before {
          content: "\f00d";
        }
      }
    }
  
    .t-message-content {
      display: flex;
      align-items: center;
  
      .t-message-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        min-width: 35px;
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
  
        &:before {
          font-family: "Font Awesome 6 Pro";
        }
      }
  
      .t-message-inner {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
  
        .t-message-inner-status {
          font-weight: 400;
          font-size: 1.1rem;
        }
  
        .t-message-inner-text {
          font-weight: 300;
        }
      }
    }
  
    .t-message-close-btn {
      position: absolute;
      top: 10px;
      right: 15px;
      padding: 5px;
      cursor: pointer;
      opacity: 0.7;
  
      &:hover {
        opacity: 1;
      }
    }
  }
}

.notice {
  position: relative;
  background-color: #0F1A33;
  color: #FFFFFF;
  text-align: center;
  padding: 30px 0;

  h1 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #D0AFE1;
  }

  h3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 2px;
    color: #E5F3FF;
  }

  p {
    font-size: 24px;
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
  }
}
@include media-breakpoint-down(lg) {
  .notice {
    padding: 20px 0;

    h1 {
      font-size: 38px;
    }

    p {
      font-size: 16px;
    }
  }
}

nav.main-nav {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 5px rgba(0,0,0,0.1);
  box-shadow: 0 0 5px rgba(0,0,0,0.1);

  .logo-sm {
    max-width: 150px;
  }

  .right, .left {
    flex-grow: 1;
    flex-basis: 0;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .logo {
    max-width: 200px;
  }

  .social {
    margin-left: -5px;

    a {
      color: #FFFFFF;
      margin-right: .5rem;
      text-decoration: none;

      i.fa-circle {
        color: #0F1A33;
        -webkit-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #FFFFFF;

        i.fa-circle {
          color: #D0AFE1;
        }
      }
    }
  }

  .nav-buttons {
    .nav-item {
      margin-right: 5px;
  
      &:last-child {
        margin: 0;
      }
    }
  }

  .navbar-toggler {
    border: none;
    background: transparent !important;
    padding: 0;

    &:focus, 
    &:active {
      outline: 0;
      text-decoration: none;
      box-shadow: none;
    }

    .icon-bar {
      display: block;
      width: 33px;
      height: 3px;
      margin-top: 6px;
      margin-bottom: 6px;
      transition: all 0.2s;
      background-color: #0F1A33;
    }

    &[aria-expanded="true"] {
      .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
  
      .middle-bar {
        opacity: 0;
      }
  
      .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }


    .top-bar {
      transform: rotate(0);
    }

    .middle-bar {
      opacity: 1;
    }

    .bottom-bar {
      transform: rotate(0);
    }
  }
}

@include media-breakpoint-down(lg) {
  nav.main-nav {
    .navbar-nav {
      margin: 20px 0 0;

      &.nav-buttons {
        flex-direction: row !important;
        margin-bottom: 20px;
      }
    }

    .right {
      display: flex;
      justify-content: flex-start;
    }
  }
}

main.hero {
  background-color: #2FA0FF;
  min-height: 800px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  .image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0 right -300px;
    z-index: -2;
  }

  .content {
    margin: 150px 0;

    .moving-laredo {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 24px;
    }
  
    .motto {
      color: #0F1A33;
      font-weight: 900;
      font-size: 80px;
      line-height: 80px;
      text-shadow:
      1px 1px 0 #FFF,
      -1px 1px 0 #FFF,
      -1px -1px 0 #FFF,
      1px -1px 0 #FFF;
    }
  
    .team-form {
      margin-top: 10px;
  
      .row > * {
        padding: 0;
      }
  
      .zip {
        padding-right: 15px;
      }
  
      input {
        font-weight: 300;
        font-size: 16px;
        padding: 15px;
        margin-bottom: 15px;
        width: 100%;
        border: none;
      }
    }
  
    .button {
      margin: 0 0 15px 0;
      width: 280px;
      text-align: center;
    }
  
    .disclaimer {
      color: #FFFFFF;
    }
  }

  @include media-breakpoint-down(xl) {
    .image {
      background-position: bottom 0 right -350px;
    }
  }

  @include media-breakpoint-down(lg) {
    .image {
      top: -140px;
      background-position: bottom 0 right -650px;
    }
  }

  @include media-breakpoint-down(md) {
    .image {
      top: -160px;
      background-position: bottom 0 right -800px;
    }

    .content {
      margin: 275px 0 25px;
    }
  }

  @include media-breakpoint-down(sm) {
    .image {
      background-position: bottom 0 right -800px;
    }

    .content {
      .moving-laredo {
        font-size: 18px;
      }

      .motto {
        font-size: 64px;
        line-height: 64px;
      }

      .button {
        text-align: center;
        width: 100%;
      }

      .disclaimer {
        font-size: .8rem;
      }
    }
  }
}

section.meet-tannya {
  position: relative;
  min-height: 400px;
  padding: 60px 0;

  .title {
    color: #29265B;
    font-size: 6rem;
    font-weight: 900;
    line-height: 5.8rem;
  }

  .content {
    color: #29265B;
    font-size: 24px;
    font-weight: 300;

    .bold-text {
      font-weight: 600;
    }
  }

  .profile {
    position: absolute;
    bottom: 0;

    img {
      max-width: 400px;
    }
  }

  @include media-breakpoint-down(xl) {
    .title {
      margin-bottom: 40px;
    }

    .content {
      padding: 0 30px;
    }

    .profile {
      position: unset;
      margin-bottom: -60px;
      text-align: center;

      img {
        margin-top: 40px;
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .title {
      font-size: 4rem;
      line-height: 3.8rem;
    }

    .content {
      padding: 0;
      font-size: 1.2rem;
    }

    .profile {
      img {
        width: 80%;
      }
    }
  }
}

section.media {
  min-height: 200px;
  background-color: #E5F3FF;

  .video-container {
    margin: 50px 0;
    text-align: center;

    video {
      max-width: 90%;
      height: auto;
      border-radius: 20px;
      -webkit-box-shadow: 6px 6px 5px -4px rgba(0,0,0,0.23);
      -moz-box-shadow: 6px 6px 5px -4px rgba(0,0,0,0.23);
      box-shadow: 6px 6px 5px -4px rgba(0,0,0,0.23);
    }
  }

  @include media-breakpoint-down(md) {
    .video-container {
      video {
        max-width: 100%;
      }
    }
  }
}

section.cta {
  background-color: #2FA0FF;
  min-height: 600px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;

  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgb(47,160,255);
    background: linear-gradient(45deg, rgba(47,160,255,1) 0%, rgba(47,160,255,0) 60%);
    z-index: -1;
  }

  .banner {
    position: absolute;
    left: -60px;
    top: -100px;
    bottom: 0;
    width: calc(100% + 60px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom 0 right -200px;
    z-index: -2;
  }

  .content {
    margin: 200px 0;

    .get-involved {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 24px;
    }
  
    .building-tomorrow {
      color: #0F1A33;
      font-weight: 900;
      font-size: 64px;
      line-height: 64px;
      text-shadow:
      1px 1px 0 #FFF,
      -1px 1px 0 #FFF,
      -1px -1px 0 #FFF,
      1px -1px 0 #FFF;
    }
  
    .cta-buttons {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(xl) {
    .banner {
      background-position: bottom 0 right -350px;
    }

    .content {
      .building-tomorrow {
        font-size: 56px;
        line-height: 56px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .banner {
      background-position: bottom 0 right -400px;
    }

    .content {
      .building-tomorrow {
        font-size: 56px;
        line-height: 56px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .banner {
      top: -200px;
      background-position: bottom 0 right -450px;
    }

    .content {
      margin: 325px 0 75px;

      .building-tomorrow {
        font-size: 48px;
        line-height: 48px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .banner {
      background-position: bottom 0 right -550px;
    }

    .content {
      .get-involved {
        font-size: 18px;
      }
    }
  }
}

footer {
  .footer {
    background-color: #0F1A33;
    min-height: 600px;  

    .container-xxl {
      position: relative;
      z-index: 0;

      .vamos-por-d4 {
        position: absolute;
        top: 100px;
        font-size: 6rem;
        font-weight: 900;
        width: 300px;
        line-height: 7rem;
        color: #12203F;
        z-index: -1;
      }

      .flower {
        position: absolute;
        top: 75px;
        right: 15px;
        z-index: -1;
        
        img {
          width: 350px;
        }
      }
    }

    .logo {
      display: block;
      max-width: 400px;
      margin: 0 auto;
      padding: 40px;
    }

    .social {
      a {
        color: #0F1A33;
        text-decoration: none;

        i.fa-circle {
          color: #FFFFFF;
          -webkit-transition: color 0.2s ease-in-out;
          transition: color 0.2s ease-in-out;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #FFFFFF;

          i.fa-circle {
            color: #D0AFE1;
          }
        }
      }
    }

    .nav-links {
      padding: 30px 0;
      margin-left: -20px;
      margin-bottom: 0;
      list-style: none;

      li {
        display: inline;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: #FFFFFF;
          font-size: 20px;
          text-decoration: none;
  
          &:hover {
            color: #D0AFE1;
          }
        }
      }
    }

    .contact-info {
      color: #FFFFFF;
      font-weight: 800;
      font-size: 20px;
      margin: 50px 0;

      a {
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .footer {
      .container-xxl {
        .vamos-por-d4,
        .flower {
          display: none;
        }
      }

      .nav-links {
        margin-left: 0;
        
        li {
          margin-bottom: 20px;
          margin-right: 0;
          display: block;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .sub-footer {
    padding: 25px 0;
    background-color: #0B1427;
    color: #1C3264;
    z-index: 1;
    
    .attribution,
    .copyright {
      font-weight: 300;
    }

    .contribution {
      .contribution-inner {
        display: inline-block;
        border: 2px solid #FFFFFF;
        padding: 5px 10px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

    .attribution {
      span {
        a {
          color: #1C3264;
          font-weight: 600;
          text-decoration: none;
  
          &:hover {
            color: #D0AFE1;
          }
      }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .sub-footer {
      padding: 40px 0;

      .contribution,
      .attribution {
        margin-bottom: 20px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .footer {
      .logo {
        margin: 20px auto;
        max-width: 300px;
      }

      .social {
        a {
          margin-right: 0;
        }
      }
    }
  }
}